
@import '@core/styles/colors.scss';


.shadow-box{
    border-radius: 15px;
    box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.07);
    padding: 30px 36px;
    margin-bottom: 15px;
}

.trial{
    background-image: url(../../assets/images/trial-banner-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
}