@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.wrapper {
    padding: 40px !important;
    background-color: white;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.10);
    border-radius: 0px 0px 15px 15px;
    position: relative;
    overflow: hidden;
    background-image: url(../../../assets/images/pages/menu/mega-menu-bg.png);
    background-repeat: no-repeat;
    background-position: center right;

    &>div{
        min-height: 220px;
    }

    .main-item-wrapper {
        border-right: 1px solid $colorGray25;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 48px;

        .main-item{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-size: 16px;
            font-weight: 700;

            &.active{
                color: $colorP1;                
            }

            &:hover{
                cursor: pointer;
            }
        }
    }

  
}